import _ from 'lodash';

const ServerMessages = function () {
  const self = this;

  /* eslint-disable quote-props */
  self.errorMessages = {
    'unconfirmed_email_address': 'Please confirm your email address before posting!',
    'article_content_too_short': 'Content must contain at least 250 words.',
    'invalid.email.address': 'The given email address is not valid.',
    'password.not.long.enough': 'Your password is not long enough.',
    'password.not.complex.enough': 'Your password is not complex enough.',
    'password.contains.forbidden.substrings': 'Your password must not contain your email address.',
    'birthyear.below.min': 'You must be 18 or older to join the uTest community.',
    'birthyear.over.max': 'Please enter a valid birth date.',
    'user.email.exists': 'An account with the given email address already exists.',
    'email.in.use': 'An account with the given email address already exists.',
    'tester.environment.required': 'Please select at least one desktop or mobile device.',
    'invalid_captcha_response': 'Wrong captcha solution.',
    'invalid_recaptcha_response': 'Invalid recaptcha response.',
    'invalid.user.name': 'Invalid characters in user name, Please remove special characters.',
    'user_over_daily_post_limit': 'Post limit reached',
    'password.contains.email': 'Password cannot contain your email',
    'password.min.lower': 'Invalid Password: password must contain at least 1 lower case character.',
    'password.min.upper': 'Invalid Password: password must contain at least 1 upper case character.',
    'password.min.digit': 'Invalid Password: password must contain at least 1 digit.',
  };
  self.errorMessagesPrefixes = ['Invalid password:'];
  /* eslint-enable */

  self.prettyMessage = function (apiResponse) {
    const errorCode = _.get(apiResponse, 'data.errors[0].code');
    const errorMessage = self.errorMessages[errorCode];

    if (errorMessage) {
      return errorMessage;
    }
    if (errorCode) {
      if (self.hasPrefix(errorCode, self.errorMessagesPrefixes)) {
        return errorCode;
      }
      return `There was an error: ${errorCode}. Please contact Community Management for help.`;
    }

    const errorBody = apiResponse.data;
    if ((typeof errorBody === 'string') && !errorBody.startsWith('<')) {
      return errorBody;
    }
    return apiResponse.statusText;
  };

  self.hasPrefix = function (message, prefixes) {
    if (!message) return false;
    if (!prefixes || prefixes.length === 0) return false;
    for (let i = 0; i < prefixes.length; i++) {
      if (message.startsWith(prefixes[i])) return true;
    }
    return false;
  };
};

ServerMessages.$inject = [];
export default ServerMessages;
