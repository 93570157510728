import {
  Component, Inject, Input, OnInit,
} from '@angular/core';
import { StateService } from '@uirouter/core';

@Component({
  selector: 'ut-comment-btn',
  templateUrl: './comment-btn.component.html',
})

export class CommentBtnComponent implements OnInit {
  @Input() commentable: any;
  ariaLabel: string = '';

  constructor(
    @Inject('$state') private $state: StateService,
    @Inject('LightTesterErrorService') private LightTesterErrorService: any,
  ) {}

  ngOnInit(): void {
    if (this.commentable?.post_count < 0) {
      this.commentable.post_count = 0;
    }
    if (this.commentable?.comments?.count < 0) {
      this.commentable.comments.count = 0;
    }
    if (this.commentable?.type === 'article') {
      this.ariaLabel = `to article, ${this.commentable.title}, ${this.commentable.comments.count} comments ${this.commentable.comments.commented_by_current_user ? ' - you have commented on this article' : ''}`;
    } else if (this.commentable?.type === 'topic') {
      this.ariaLabel = `to topic, ${this.commentable.title}, ${this.commentable.post_count} comments ${this.commentable.comments.commented_by_current_user ? ' - you have commented on this topic' : ''}`;
    } else if (this.commentable?.type === 'status') {
      this.ariaLabel = `to status, by ${this.commentable.user.name}, ${this.commentable.comments.count} comments ${this.commentable.comments.commented_by_current_user ? ' - you have commented on this status' : ''}`;
    }
  }

  navigateAfterCheckingForLightTester = () => {
    if (this.LightTesterErrorService.show()) {
      return;
    }
    switch (this.commentable.type) {
      case 'article':
        this.$state.go('singlearticle', { articleId: this.commentable.slug });
        break;
      case 'topic':
        this.$state.go('singlediscussionpage', { page: 1, discussionId: this.commentable.id });
        break;
      case 'status':
        this.$state.go('singlestatus', { statusId: this.commentable.id });
        break;
      default:
        break;
    }
  };
}
