/*
 * Copyright (C) 2021 to Present Applause App Quality, Inc. All rights reserved.
 */

import _ from 'lodash';
import { EMPTY_CONTENT_MARKDOWNS } from '../../constants';

class CommentSectionCtrl {
  constructor($scope, $state, $location, $sce, $stateParams, PaginatorService, stateStore, CommentService, AlertService, $filter, $timeout, commentEmojis, UserService, ProgressBarService, ServerMessages, LightTesterErrorService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      ctrl.replyFocusIndex = null;
      ctrl.writeCommentOpened = false;
      ctrl.currentUser = null;
      ctrl.selectedComment = $location.hash().toString().split('_')[1];
      UserService.me().then((res) => {
        if (res !== null) {
          ctrl.currentUser = res.data;
        } else {
          ctrl.currentUser = null;
          ctrl.loginUrl = `/login?return_to=${$location.absUrl()}`;
        }
      });

      ctrl.search = {
        query: '',
        submit() {
          if (!ctrl.isLoading) {
            ctrl.loadPage(1);
          }
        },
        clear() {
          ctrl.search.query = '';
        },
      };

      const stateGoOptions: any = { notify: false };

      if (ctrl.commentable.type === 'topic') {
        stateGoOptions.location = 'replace';
        $scope.$watch('search.query', (newQuery, oldQuery) => {
          if (newQuery !== oldQuery) {
            ctrl.search.submit();
          }
        });

        ctrl.sorting = $location.search().sort ? $location.search().sort : 'oldest';
        ctrl.sortingOptions = {
          oldest: 'Oldest first',
          newest: 'Newest first',
        };
      } else {
        stateGoOptions.location = 'replace';
        ctrl.sorting = $location.search().sort ? $location.search().sort : '-threaded';
        ctrl.sortingOptions = {
          '-threaded': 'Newest first',
          threaded: 'Oldest first',
          liked_threaded: 'Popular first',
        };
      }

      ctrl.sortingChanged = function (val) {
        ctrl.sorting = val;
        if (!ctrl.isLoading) {
          ctrl.loadPage(1, false, true);
        }
      };

      ctrl.wrapperClass = ctrl.commentable.type;
      if (ctrl.commentable.type !== 'topic') {
        ctrl.wrapperClass += ' section-single-comment-dark';
      }

      const getParamName = ctrl.commentable.type === 'topic' ? 'page' : 'comments';
      const pageFuncName = ctrl.commentable.type === 'topic' ? 'getPage' : 'commentsPage';

      const indentations = {};

      ctrl.loadPage = function (page, initialLoad, forceUrlChange) {
        ctrl.isLoading = true;
        const id = !ctrl.commentable.slug ? ctrl.commentable.id : ctrl.commentable.slug;
        ctrl.service[pageFuncName](id, page, ctrl.sorting, ctrl.search.query).then((res) => {
          ctrl.comments = _.map(res.data, (o) => {
            o.content_built = $sce.trustAsHtml($filter('uMarkdown')(o.content));
            return CommentService.addIndentation(o, indentations);
          });
          ctrl.paginationData = PaginatorService.makePaginationData(res.headers);
          ctrl.isLoading = false;
          if (initialLoad) {
            $('#comment').ready(() => {
              const el = document.getElementById($location.hash());
              if (el) {
                el.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
            });
          } else {
            const containers = $('[ui-view$="-show"]');
            const comment = $('#comment');
            comment.ready(() => {
              if ($(comment).offset() !== undefined) {
                containers.each((idx, component) => {
                  if ($.contains(component, $(comment)[0])) {
                    $(component).scrollTop($(comment).offset().top - 50);
                  }
                });
              }
              if (ctrl.replyFocusIndex != null) {
                $(`#reply_${ctrl.replyFocusIndex}`).focus();
              }
              ctrl.replyFocusIndex = null;
            });
          }

          if (ctrl.commentable.comments) {
            ctrl.commentable.comments.count = ctrl.paginationData.totalItems;
          }
        }, (res) => {
          ctrl.isLoading = false;
          AlertService.addDanger(res.statusText);
        });
        if ((ctrl.paginationData && ctrl.paginationData.currentPage !== page && !ctrl.search.query) || forceUrlChange) {
          let itemState;
          if (ctrl.commentable.type === 'topic') {
            itemState = { state: 'singlediscussionpage', params: { discussionId: ctrl.commentable.id } };
          } else {
            itemState = stateStore(ctrl.commentable, true);
          }
          const params: any = {};
          params[getParamName] = page;
          if (ctrl.sorting) {
            params.sort = ctrl.sorting;
          }
          $state.go(itemState.state, _.merge(itemState.params, params), stateGoOptions);
        }
      };

      const getPageNumber = ctrl.commentable.type === 'topic' ? $stateParams[getParamName] : parseInt($location.search()[getParamName]);
      const initialPage = !getPageNumber ? 1 : getPageNumber;
      ctrl.loadPage(initialPage, true);

      ctrl.setComment = function (comment) {
        ctrl.newComment = comment;
      };

      ctrl.broadcastOpening = function () {
        $scope.$broadcast('openCommentEditor', 'singleCommentSection');
      };

      ctrl.setQuote = function (comment) {
        ctrl.newComment = {
          content: `> @${comment.user.slug} wrote: \n> ${comment.content.split('\n').join('\n> ')}\n\n`,
        };
      };

      ctrl.deleteComment = function (id) {
        CommentService.deleteComment(id).then(() => {
          if (ctrl.comments.length > 1 || ctrl.paginationData.currentPage === 1) {
            ctrl.loadPage(ctrl.paginationData.currentPage);
          } else {
            ctrl.loadPage(ctrl.paginationData.currentPage - 1);
          }
          AlertService.add('Comment successfully deleted');
        });
      };

      ctrl.openCommentEditor = (comment = { content: '' }, type = 'comment') => {
        if (LightTesterErrorService.show()) return false;
        if (type === 'comment') {
          ctrl.setComment(comment);
        } else if (type === 'quote') {
          ctrl.setQuote(comment);
        }
        $scope.toggleSlideUp('singleCommentSection');
      };

      ctrl.submitComment = function () {
        const trimmedComment = _.trim(ctrl.newComment.content);
        if (_.isEmpty(trimmedComment) || EMPTY_CONTENT_MARKDOWNS.includes(trimmedComment)) {
          AlertService.addDanger('Can\'t post empty comment!');
          return;
        }

        ProgressBarService.start();
        if (!ctrl.newComment.id) {
          const replyIndex = ctrl.newComment.reply_index;
          delete ctrl.newComment.reply_index;
          CommentService.postComment(ctrl.commentable.type, ctrl.commentable.id, ctrl.newComment).then((res) => {
            if (!res.data.parent_id) {
              if (ctrl.sorting === 'newest' || ctrl.sorting === '-threaded') {
                ctrl.loadPage(1);
              } else {
                const lastPage = Math.ceil((ctrl.paginationData.totalItems + 1) / ctrl.paginationData.perPage);
                ctrl.loadPage(lastPage, lastPage === ctrl.paginationData.currentPage);
              }
            } else if (replyIndex === 9) {
              ctrl.loadPage(ctrl.paginationData.currentPage + 1);
            } else {
              ctrl.loadPage(ctrl.paginationData.currentPage);
            }

            AlertService.add('Comment successfully posted');
            $scope.$broadcast('toggleSlideUp', 'singleCommentSection');
            ProgressBarService.done();
          }, (response) => {
            AlertService.addDanger(ServerMessages.prettyMessage(response));
            ProgressBarService.done();
          });
        } else {
          CommentService.editComment(ctrl.newComment.id, ctrl.newComment).then(() => {
            $scope.$broadcast('toggleSlideUp', 'singleCommentSection');
            AlertService.add('Comment successfully changed');
            ctrl.loadPage(ctrl.paginationData.currentPage);
            ProgressBarService.done();
          });
        }
      };

      if (!ctrl.commentBtnLabel) {
        ctrl.commentBtnLabel = 'Write a Comment';
      }

      ctrl.focusBtn = (triggerBtn) => {
        switch (triggerBtn) {
          case 'delete':
            $('#deleteCommentBtnNo').attr('aria-hidden', 'false');
            $timeout(() => {
              $('#deleteCommentBtn').attr('aria-hidden', 'true');
              $('#deleteCommentBtnNo').focus();
            }, 150);
            break;
          case 'no':
            $('#deleteCommentBtn').attr('aria-hidden', 'false');
            $timeout(() => {
              $('#deleteCommentBtnNo').attr('aria-hidden', 'true');
              $('#deleteCommentBtn').focus();
            }, 150);
            break;
          default:
        }
      };

      ctrl.handleKeydownDeleteConformation = (event) => {
        const id = event.target.attributes.id.value;
        if (!event.shiftKey && event.keyCode === 9 && id === 'deleteCommentBtnYes') {
          event.preventDefault();
          $('#deleteCommentBtnNo').focus();
        } else if (event.shiftKey && event.keyCode === 9 && id === 'deleteCommentBtnNo') {
          event.preventDefault();
          $('#deleteCommentBtnYes').focus();
        }
      };

      ctrl.emojiImages = commentEmojis;

      ctrl.selectEmoji = function (emojiCode) {
        ctrl.setMode('');
        let space = '';
        if (_.isUndefined(ctrl.newComment.content)) {
          ctrl.newComment.content = '';
        } else {
          space = ctrl.newComment.content.slice(-1) === ' ' ? '' : ' ';
        }
        ctrl.newComment.content += space + emojiCode;
      };

      ctrl.mode = '';
      ctrl.setMode = function (mode) {
        ctrl.mode = ctrl.mode === mode ? '' : mode;
      };

      ctrl.commentDeletionClass = '';
    };
  }
}

CommentSectionCtrl.$inject = ['$scope', '$state', '$location', '$sce', '$stateParams', 'PaginatorService', 'stateStore', 'CommentService', 'AlertService', '$filter', '$timeout', 'commentEmojis', 'UserService', 'ProgressBarService', 'ServerMessages', 'LightTesterErrorService'];

export default CommentSectionCtrl;
