import template from './simple-registration-form.component.html';
import controller from './simple-registration-form.controller';

const simpleRegistrationForm = {
  bindings: {
    showHeader: '<',
    customRegistration: '<',
    preparedRegistrationData: '<',
  },
  template,
  controller,
};

export default simpleRegistrationForm;
