export default `
<li class="dashboard-new-joiner-checklist__item" ng-class="{'dashboard-new-joiner-checklist__item--completed' : $ctrl.checked }">
  <div role="img" class="dashboard-new-joiner-checklist__icon" aria-label="{{$ctrl.checked ? 'Completed' : 'Not Completed'}}">
    <i ng-if="$ctrl.checked" class="material-icons" >check_circle</i>
    <i ng-if="!$ctrl.checked" class="dashboard-new-joiner-checklist__icon--unchecked material-icons">panorama_fish_eye</i>
  </div>
  <div class="dashboard-new-joiner-checklist__text">
    <a ng-if="$ctrl.url && !($ctrl.checked && $ctrl.staticOnCompletion)" class="dashboard-new-joiner-checklist__link" ng-class="{'dashboard-new-joiner-checklist__link--completed' : $ctrl.checked }" href="{{$ctrl.url}}">{{$ctrl.urlText}}</a>
    <a ng-if="$ctrl.route && !($ctrl.checked && $ctrl.staticOnCompletion)" class="dashboard-new-joiner-checklist__link" ng-class="{'dashboard-new-joiner-checklist__link--completed' : $ctrl.checked }" ui-sref="{{$ctrl.route}}">{{$ctrl.urlText}}</a>
    <div ng-if="$ctrl.checked && $ctrl.staticOnCompletion" class="dashboard-new-joiner-checklist__link dashboard-new-joiner-checklist__link--completed">{{$ctrl.urlText}}</div>
    <div class="dashboard-new-joiner-checklist__description" ng-if="!$ctrl.checked">{{$ctrl.description}}</div>
  </div>
</li>
`;
