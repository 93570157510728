export default `
<div class="sign-up-form-container">
  <form name="communityRegistrationForm" class="user-form" ng-class="{'invalid': (!communityRegistrationForm.$valid && communityRegistrationForm.$$submitted)}">
    <div class="form-group-box">
      <div class="step-intro">
        Apply for {{$ctrl.registration.display_name}} Testing
      </div>

      <!-- BASIC INFO -->
      <div class="row">
        <div class="form-group col-xs-12 col-md-6">
          <div>
            <label for="firstName">First name:</label>
          </div>
          <input required type="text" id="firstName" name="firstName" aria-label="First name" placeholder="John" ng-model="$ctrl.registrationData.firstName" ng-pattern="$ctrl.name_pattern" ng-minlength="2" ng-auto-focus="{onlyFocusWhenEmpty: true}"
                 focus-on="firstNameFocus"
                 aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.firstName.$touched && communityRegistrationForm.firstName.$dirty)) && !!communityRegistrationForm.firstName.$invalid ? 'firstNameError' : ''}}"
                 aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.firstName.$touched && communityRegistrationForm.firstName.$dirty)) && !!communityRegistrationForm.firstName.$invalid}}">
          <i aria-hidden="true" class="validation-icon material-icons">{{communityRegistrationForm.firstName.$valid ? 'done': 'warning'}}</i>
          <span id="firstNameError" class="error-msg" ng-show="!communityRegistrationForm.firstName.$valid">
            {{communityRegistrationForm.firstName.$error.required ? 'First name is required' : (communityRegistrationForm.firstName.$error.pattern ? 'No special characters': 'Minimum 2 characters required')}}
          </span>
        </div>
        <div class="form-group col-xs-12 col-md-6">
          <div>
            <label for="lastName">Last name:</label>
          </div>
          <input required type="text" id="lastName" name="lastName" aria-label="Last name" placeholder="Smith" ng-model="$ctrl.registrationData.lastName" ng-pattern="$ctrl.name_pattern" ng-minlength="2"
                 aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.lastName.$touched && communityRegistrationForm.lastName.$dirty)) && !!communityRegistrationForm.lastName.$invalid ? 'lastNameError' : ''}}"
                 aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.lastName.$touched && communityRegistrationForm.lastName.$dirty)) && !!communityRegistrationForm.lastName.$invalid}}">
          <i aria-hidden="true" class="validation-icon material-icons">{{communityRegistrationForm.lastName.$valid ? 'done': 'warning'}}</i>
          <span id="lastNameError" class="error-msg" ng-show="!communityRegistrationForm.lastName.$valid">
            {{communityRegistrationForm.lastName.$error.required ? 'Last name is required' : (communityRegistrationForm.lastName.$error.pattern ? 'No special characters': 'Minimum 2 characters required')}}
          </span>
        </div>
        <div class="form-group col-xs-12">
          <div>
            <label for="email">Email address:</label>
          </div>
          <input mail-suggest required id="email" name="email" type="email" placeholder="jsmith@example.com" ng-model="$ctrl.registrationData.email" ng-model-options="{ debounce: 500 }" ng-pattern="email_pattern" autocomplete="email"
                 aria-label="Email address"
                 aria-describedby="emailSuggestionDescription {{(communityRegistrationForm.$$submitted || (communityRegistrationForm.email.$touched && communityRegistrationForm.email.$dirty)) && !!communityRegistrationForm.email.$invalid ? 'emailError' : ''}}"
                 aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.email.$touched && communityRegistrationForm.email.$dirty)) && !!communityRegistrationForm.email.$invalid}}">
          <i aria-hidden="true" class="validation-icon material-icons">{{(communityRegistrationForm.email.$valid || communityRegistrationForm.email.$pending)? 'done': 'warning'}}</i>
          <div style="overflow: hidden; width: 0; height: 0;" aria-hidden="false" tabindex="-1" id="emailSuggestionDescription">
            <div aria-live="assertive">{{ mailSuggestionDescription }}</div>
          </div>
          <span id="emailError" class="error-msg" ng-show="!communityRegistrationForm.email.$valid && !communityRegistrationForm.email.$pending ">
            Enter valid email
          </span>
        </div>
        <div class="form-group col-xs-12 select2-theme-form">
          <div>
            <label id="birthLabel" for="birthDate">Date of birth:</label>
          </div>
          <div>
            <div id="birthDate" name="birthDate" class="ui-select-box" aria-describedby="birthDateError" aria-label="Date of birth">
              <div class="col-xs-5 col-md-5 col-lg-5 months">
                <select class="signup-form-input-select" id="birthMonth" focus-on="birthMonthFocus" ng-model="$ctrl.registrationData.birthDate.month"
                        aria-label="Birth month"
                        ng-required="true" name="birthMonth" ng-change="$ctrl.selectMonth()"
                        ng-options="month.id as month.name for month in $ctrl.months | filter: $select.search"
                        aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.birthMonth.$touched && communityRegistrationForm.birthMonth.$dirty)) && !!communityRegistrationForm.birthMonth.$invalid ? 'birthDateError' : ''}}"
                        aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.birthMonth.$touched && communityRegistrationForm.birthMonth.$dirty)) && !!communityRegistrationForm.birthMonth.$invalid}}">
                  <option disabled selected value>Month</option>
                </select>
              </div>
              <div class="col-xs-3 col-md-3 col-lg-3 days">
                <select class="signup-form-input-select" id="birthDay" focus-on="birthDayFocus" ng-model="$ctrl.registrationData.birthDate.day"
                        aria-label="Birth day"
                        ng-required="true" name="birthDay" ng-change="$ctrl.selectDay()"
                        ng-options="day as day for day in $ctrl.days | filter: $select.search"
                        aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.birthDay.$touched && communityRegistrationForm.birthDay.$dirty)) && !!communityRegistrationForm.birthDay.$invalid ? 'birthDateError' : ''}}"
                        aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.birthDay.$touched && communityRegistrationForm.birthDay.$dirty)) && !!communityRegistrationForm.birthDay.$invalid}}">
                  <option disabled selected value>Day</option>
                </select>
              </div>
              <div class="col-xs-4 col-md-4 col-lg-4 years">
                <select class="signup-form-input-select" id="birthYear" focus-on="birthYearFocus" ng-model="$ctrl.registrationData.birthDate.year"
                        aria-label="Birth year"
                        ng-required="true" name="birthYear" ng-change="$ctrl.selectYear()"
                        ng-options="year as year for year in $ctrl.years | filter: $select.search"
                        aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.birthYear.$touched && communityRegistrationForm.birthYear.$dirty)) && !!communityRegistrationForm.birthYear.$invalid ? 'birthDateError' : ''}}"
                        aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.birthYear.$touched && communityRegistrationForm.birthYear.$dirty)) && !!communityRegistrationForm.birthYear.$invalid}}">
                  <option disabled selected value>Year</option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" aria-live="polite">
                <span id="birthDateError" class="error-msg" ng-show="communityRegistrationForm.birthDay.$invalid || communityRegistrationForm.birthMonth.$invalid || communityRegistrationForm.birthYear.$invalid">
                  Date of birth is required
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ADDRESS -->
      <div class="row">
        <div class="form-group address col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div>
            <label id="cityLabel" for="city">City:</label>
          </div>
          <input required type="text" id="city" name="city" ng-model="$ctrl.registrationData.city" aria-label="City" ng-minlength="2" ng-maxlength="50" ng-change="$ctrl.cityFieldEdited()" ng-class="{'ng-touched': communityRegistrationForm.city.$valid}"
                 aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.city.$touched && communityRegistrationForm.city.$dirty)) && !!communityRegistrationForm.city.$invalid ? 'cityError' : ''}}"
                 aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.city.$touched && communityRegistrationForm.city.$dirty)) && !!communityRegistrationForm.city.$invalid}}">
          <i aria-hidden="true" class="validation-icon material-icons">{{communityRegistrationForm.city.$valid ? 'done': 'warning'}}</i>
          <span id="cityError" class="error-msg" ng-show="!communityRegistrationForm.city.$valid" ng-messages="communityRegistrationForm.city.$error" aria-live="polite">
            <ng-message when="required">City is required</ng-message>
            <ng-message when="minlength">Minimum 2 characters required</ng-message>
            <ng-message when="maxlength">Maximum 50 characters</ng-message>
            <ng-message when="validationMatch">Select a valid city from the list</ng-message>
          </span>
        </div>

        <div class="form-group address col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div>
            <label id="zipLabel" for="zip">Zip or Postal Code:</label>
          </div>
          <input required type="text" id='zip' name="zip" ng-model="$ctrl.registrationData.zip" aria-label="Zip or Postal Code" ng-minlength="2" ng-maxlength="20" ng-class="{'ng-touched': communityRegistrationForm.zip.$valid}"
                 aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.zip.$touched && communityRegistrationForm.zip.$dirty)) && !!communityRegistrationForm.zip.$invalid ? 'zipError' : ''}}"
                 aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.zip.$touched && communityRegistrationForm.zip.$dirty)) && !!communityRegistrationForm.zip.$invalid}}">
          <i aria-hidden="true" class="validation-icon material-icons">{{communityRegistrationForm.zip.$valid ? 'done': 'warning'}}</i>
          <span id="zipError" class="error-msg" ng-show="!communityRegistrationForm.zip.$valid" ng-messages="communityRegistrationForm.zip.$error" aria-live="polite">
            <ng-message when="required">Zip is required</ng-message>
            <ng-message when="minlength">Minimum 2 characters required</ng-message>
            <ng-message when="maxlength">Maximum 20 characters</ng-message>
          </span>
        </div>

        <div class="form-group address col-xs-12 col-sm-12 col-md-6 col-lg-6 select2-theme-form">
          <div>
            <label id="countryLabel">Country:</label>
          </div>
          <div class="ui-select">
            <div>
              <select class="signup-form-input-select" id="countryId" focus-on="countryIdFocus" ng-model="$ctrl.registrationData.countryId"
                      aria-label="Country"
                      ng-required="true" name="countryId" ng-change="$ctrl.selectCountry()"
                      ng-options="country.id as country.name for country in $ctrl.countries | filter: $select.search"
                      aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.countryId.$touched && communityRegistrationForm.countryId.$dirty)) && !!communityRegistrationForm.countryId.$invalid ? 'countryError' : ''}}"
                      aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.countryId.$touched && communityRegistrationForm.countryId.$dirty)) && !!communityRegistrationForm.countryId.$invalid}}">
                <option disabled selected value>Select a country</option>
              </select>
              <span class="error-msg" ng-show="!communityRegistrationForm.countryId.$valid" aria-live="polite">
                Country is required
              </span>
            </div>
          </div>
        </div>

        <div class="form-group address col-xs-12 col-sm-12 col-md-6 col-lg-6 select2-theme-form" ng-show="$ctrl.states!=undefined">
          <div>
            <label>State, Province or Region:</label>
          </div>
          <div>
            <select class="signup-form-input-select" id="stateId" ng-disabled="!($ctrl.states && $ctrl.states.length)" focus-on="stateIdFocus" ng-model="$ctrl.registrationData.stateId"
                    aria-label="State, Province or Region"
                    ng-required="$ctrl.states && $ctrl.states.length" name="stateId"
                    ng-options="state.id as state.name for state in $ctrl.states | filter: $select.search"
                    aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.stateId.$touched && communityRegistrationForm.stateId.$dirty)) && !!communityRegistrationForm.stateId.$invalid ? 'stateError' : ''}}"
                    aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.stateId.$touched && communityRegistrationForm.stateId.$dirty)) && !!communityRegistrationForm.stateId.$invalid}}">
              <option disabled selected value>Select a state</option>
            </select>
            <span id="stateError" class="error-msg" ng-show="!communityRegistrationForm.stateId.$valid" aria-live="polite">
              State is required
            </span>
          </div>
        </div>
      </div>
      
      <!-- PASSWORD -->
      <div class="row">
        <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div>
            <label for="password">Create your password</label>
          </div>
          <input required type="password" name="password" id="password" placeholder="10-64 characters" ng-model="$ctrl.registrationData.password" autocomplete="new-password" ng-minlength="10" ng-maxlength="64"
                 community-registration-password-strength-meter registration-data="$ctrl.registrationData" aria-label="Create your password"
                 aria-describedby="passwordInstruction"
                 aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.password.$touched && communityRegistrationForm.password.$dirty)) && !!communityRegistrationForm.password.$invalid}}">
          <i aria-hidden="true" class="validation-icon material-icons">{{communityRegistrationForm.password.$valid ? 'done': 'warning'}}</i>
          <div class="sr-only" id="passwordInstruction" aria-hidden="true">
            Password Instructions, Use 10 to 64 characters. Must include a lower-case letter, an upper-case letter, and a number. Must not include email address. For added security, we do not allow common passwords. Password is case sensitive. Avoid using the same password for multiple sites.
          </div>
        </div>
        <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div>
            <label for="confirmPassword">Confirm password</label>
          </div>
          <input required type="password" name="confirmPassword" id="confirmPassword" placeholder="10-64 characters" validation-match="$ctrl.registrationData.password" autocomplete="new-password" ng-minlength="10" ng-maxlength="64"
                 ng-model="$ctrl.registrationData.confirmPassword" aria-label="Confirm password"
                 aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.confirmPassword.$touched && communityRegistrationForm.confirmPassword.$dirty)) && !!communityRegistrationForm.confirmPassword.$invalid ? 'passConfirmError' : ''}}"
                 aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.confirmPassword.$touched && communityRegistrationForm.confirmPassword.$dirty)) && !!communityRegistrationForm.confirmPassword.$invalid}}">
          <i aria-hidden="true" class="validation-icon material-icons">{{communityRegistrationForm.confirmPassword.$valid ? 'done': 'warning'}}</i>
          <span id="passConfirmError" class="error-msg" ng-show="!communityRegistrationForm.confirmPassword.$valid" aria-live="polite">
            {{communityRegistrationForm.confirmPassword.$error.required ? 'Confirm Password is required' : 'Password mismatch'}}
          </span>
        </div>
      </div>

      <!-- DEVICES -->
      <div class="row">
        <div id="web-device">
          <div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4 select2-theme-form">
            <div>
              <label>Your Computer:</label>
            </div>
            <div class="ui-select">
              <select class="signup-form-input-select" id="osId" focus-on="osIdFocus" ng-model="$ctrl.registrationData.webDevice.osId"
                      aria-label="Your Computer"
                      ng-required="true" name="osId" ng-change="$ctrl.getWebOSVersions()"
                      ng-options="device.id as device.name for device in $ctrl.webDevices | filter: {'name': $select.search}"
                      aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.osId.$touched && communityRegistrationForm.osId.$dirty)) && !!communityRegistrationForm.osId.$invalid ? 'osIdError' : ''}}"
                      aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.osId.$touched && communityRegistrationForm.osId.$dirty)) && !!communityRegistrationForm.osId.$invalid}}">
                <option selected value>Select OS</option>
              </select>
              <span id="osIdError" class="error-msg" ng-show="!communityRegistrationForm.osId.$valid" aria-live="polite">
                Computer is required
              </span>
            </div>
          </div>
          <div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4 select2-theme-form">
            <div>
              <label>Version:</label>
            </div>
            <div class="ui-select">
              <select class="signup-form-input-select" id="osVersionId" focus-on="osVersionIdFocus" ng-model="$ctrl.registrationData.webDevice.osVersionId"
                      ng-disabled="!$ctrl.registrationData.webDevice.osId || $ctrl.registrationData.webDevice.osId < 0"
                      aria-label="Operating system version"
                      ng-required="$ctrl.registrationData.webDevice.osId > 0" name="osVersionId"
                      ng-options="version.id as version.name for version in $ctrl.webOSVersions | filter: {'name': $select.search}"
                      aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.osVersionId.$touched && communityRegistrationForm.osVersionId.$dirty)) && !!communityRegistrationForm.osVersionId.$invalid ? 'osVersionError' : ''}}"
                      aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.osVersionId.$touched && communityRegistrationForm.osVersionId.$dirty)) && !!communityRegistrationForm.osVersionId.$invalid}}">
                <option selected value>Select a Version</option>
              </select>
              <span id="osVersionError" class="error-msg" ng-show="!communityRegistrationForm.osVersionId.$valid" aria-live="polite">
                OS version is required
              </span>
            </div>
          </div>
          <div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4 select2-theme-form">
            <div>
              <label>Language:</label>
            </div>
            <div class="ui-select">
              <select class="signup-form-input-select" id="osLanguageId" focus-on="osLanguageIdFocus" ng-model="$ctrl.registrationData.webDevice.osLanguageId"
                      ng-disabled="!$ctrl.registrationData.webDevice.osId || $ctrl.registrationData.webDevice.osId < 0"
                      aria-label="Operating system language"
                      ng-required="$ctrl.registrationData.webDevice.osId > 0" name="osLanguageId"
                      ng-options="language.id as language.name for language in $ctrl.languages | filter: {'name': $select.search}"
                      aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.osLanguageId.$touched && communityRegistrationForm.osLanguageId.$dirty)) && !!communityRegistrationForm.osLanguageId.$invalid ? 'languageError' : ''}}"
                      aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.osLanguageId.$touched && communityRegistrationForm.osLanguageId.$dirty)) && !!communityRegistrationForm.osLanguageId.$invalid}}">
                <option selected value>Select OS language</option>
              </select>
              <span id="languageError" class="error-msg" ng-show="!communityRegistrationForm.osLanguageId.$valid" aria-live="polite">
                OS language is required
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div id="mobile-device">
          <div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4 select2-theme-form">
            <div>
              <label>Your Mobile Device:</label>
            </div>
            <div class="ui-select">
              <select class="signup-form-input-select" id="handsetMakerId" focus-on="handsetMakerIdFocus" ng-model="$ctrl.registrationData.mobileDevice.handsetMakerId"
                      aria-label="Your Mobile Device"
                      ng-required="true" name="handsetMakerId" ng-change="$ctrl.getMobileDeviceModels()"
                      ng-options="device.id as device.name for device in $ctrl.mobileDevices | filter: {'name': $select.search}"
                      aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.handsetMakerId.$touched && communityRegistrationForm.handsetMakerId.$dirty)) && !!communityRegistrationForm.handsetMakerId.$invalid ? 'handsetMakerError' : ''}}"
                      aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.handsetMakerId.$touched && communityRegistrationForm.handsetMakerId.$dirty)) && !!communityRegistrationForm.handsetMakerId.$invalid}}">
                <option selected disabled value>Select Brand</option>
              </select>
              <span id="handsetMakerError" class="error-msg" ng-show="!communityRegistrationForm.handsetMakerId.$valid" aria-live="polite">
                Mobile device is required
              </span>
            </div>
          </div>
          <div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4 select2-theme-form">
            <div>
              <label>Model:</label>
            </div>
            <div class="ui-select">
              <select class="signup-form-input-select" id="handsetModelId" focus-on="handsetModelIdFocus" ng-model="$ctrl.registrationData.mobileDevice.handsetModelId"
                      aria-label="Mobile device model"
                      ng-disabled="!$ctrl.registrationData.mobileDevice.handsetMakerId || $ctrl.registrationData.mobileDevice.handsetMakerId < 0"
                      ng-required="$ctrl.registrationData.mobileDevice.handsetMakerId > 0"
                      name="handsetModelId" ng-change="$ctrl.getMobileDeviceOSVersions()"
                      ng-options="model.id as model.name for model in $ctrl.mobileDeviceModels | filter: {'name': $select.search}"
                      aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.handsetModelId.$touched && communityRegistrationForm.handsetModelId.$dirty)) && !!communityRegistrationForm.handsetModelId.$invalid ? 'mobileModelError' : ''}}"
                      aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.handsetModelId.$touched && communityRegistrationForm.handsetModelId.$dirty)) && !!communityRegistrationForm.handsetModelId.$invalid}}">
                <option selected value>Select a Model</option>
              </select>
              <span id="mobileModelError" class="error-msg" ng-show="!communityRegistrationForm.handsetModelId.$valid" aria-live="polite">
                Mobile device model is required
              </span>
            </div>
          </div>
          <div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4 select2-theme-form">
            <div>
              <label>Operating System:</label>
            </div>
            <div class="ui-select">
              <select class="signup-form-input-select" id="handsetOSId" focus-on="handsetOSIdFocus" ng-model="$ctrl.registrationData.mobileDevice.handsetOSId"
                      aria-label="Mobile device operating system"
                      ng-disabled="!$ctrl.registrationData.mobileDevice.handsetModelId"
                      ng-required="$ctrl.registrationData.mobileDevice.handsetMakerId > 0"
                      name="handsetOSId"
                      ng-options="osVersion.id as osVersion.name for osVersion in $ctrl.mobileDeviceOSVersions | filter: {'name': $select.search}"
                      aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.handsetOSId.$touched && communityRegistrationForm.handsetOSId.$dirty)) && !!communityRegistrationForm.handsetOSId.$invalid ? 'mobileSystemError' : ''}}"
                      aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.handsetOSId.$touched && communityRegistrationForm.handsetOSId.$dirty)) && !!communityRegistrationForm.handsetOSId.$invalid}}">
                <option selected value>Select OS</option>
              </select>
              <span id="mobileSystemError" class="error-msg" ng-show="!communityRegistrationForm.handsetOSId.$valid" aria-live="polite">
                Mobile device OS is required
              </span>
            </div>
          </div>
        </div>
      </div>


      <!-- TERMS AND CONDITIONS -->
      <div class="row">
        <div class="col-xs-12">
          <label class="input-check-box signup-consent">
            <input type="checkbox" name="termOfUse" id="termOfUse" ng-model="$ctrl.registrationData.termsOfUseConductCode" required
                   aria-label="I have read and accept the Terms of Use and the uTest Guidelines"
                   aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.termOfUse.$touched &&  communityRegistrationForm.termOfUse.$dirty)) && !!communityRegistrationForm.termOfUse.$invalid ? 'termsError' : ''}}"
                   aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.termOfUse.$touched &&  communityRegistrationForm.termOfUse.$dirty)) && !!communityRegistrationForm.termOfUse.$invalid}}">
            I have read and accept the <a class="underlined-on-focus-only" href="/terms-and-conditions" ng-click="$ctrl.showTerms('terms-and-conditions'); $event.preventDefault();" target="_blank">Terms of Use</a> and the <a class="underlined-on-focus-only" href="/utest-guidelines" ng-click="$ctrl.showTerms('/utest-guidelines'); $event.preventDefault();" target="_blank">uTest Guidelines</a>
            <span class="checkmark signup-consent__checkbox" ng-class="{error: communityRegistrationForm.termOfUse.$error.required}"></span>
            <span id="termsError" class="error-msg" ng-show="!communityRegistrationForm.termsOfUseConductCode.$valid" aria-live="polite">
              You must accept Terms of Use and uTest Guidelines to be able to continue
            </span>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <label class="input-check-box signup-consent">
            <input type="checkbox" name="privacySetting" id="privacySetting" ng-model="$ctrl.registrationData.privacySetting" required
                   aria-describedby="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.privacySetting.$touched &&  communityRegistrationForm.privacySetting.$dirty)) && !!communityRegistrationForm.privacySetting.$invalid ? 'privacyError' : ''}}"
                   aria-label="I have read and accept the Privacy & Security Policy"
                   aria-invalid="{{(communityRegistrationForm.$$submitted || (communityRegistrationForm.privacySetting.$touched &&  communityRegistrationForm.privacySetting.$dirty)) && !!communityRegistrationForm.privacySetting.$invalid}}">
            I have read and accept the <a class="underlined-on-focus-only" href="/privacy-policy" ng-click="$ctrl.showTerms('privacy-policy'); $event.preventDefault();" target="_blank">Privacy & Security Policy</a>
            <span class="checkmark signup-consent__checkbox" ng-class="{error: communityRegistrationForm.privacySetting.$error.required}"></span>
            <span id="privacyError" class="error-msg" ng-show="!communityRegistrationForm.privacySetting.$valid" aria-live="polite">
              You must accept Privacy &amp; Security Policy to be able to continue
            </span>
          </label>
        </div>
      </div>

      <!-- Submit button -->
      <div class="pull-right next-step submit-button">
        <a href="#" aria-label="Sign Up Now!" class="btn btn-blue" ng-click="$ctrl.submitForm(communityRegistrationForm);" id="laddaBtn" role="button">
          <span>Sign Up Now!</span>
        </a>
      </div>

      <!-- CAPTCHA -->
      <div vc-recaptcha
           ng-if="$ctrl.captcha_method === 'recaptcha'"
           key="$ctrl.recaptchaKey"
           required="false"
           size="invisible"
           on-create="$ctrl.setWidgetId(widgetId)"
           on-success="$ctrl.onCaptchaResponse(response)"
           on-expire="$ctrl.cbExpiration()">
      </div>
      <div ng-if="$ctrl.captcha_method === 'utest'" class="row">
        <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div>
            <label>Enter the letters from the image</label>
          </div>
          <input required name="utest_captcha" type="text" ng-model="$ctrl.utestCaptcha.response">
          <span class="error-msg" ng-show="!$ctrl.utestCaptcha.response.$valid" aria-live="polite">
            Captcha solution cannot be empty
          </span>
        </div>
        <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 text-center">
          <img src="{{$ctrl.utestCaptcha.image_url}}"><br>
          <span class="check-mark">
            <a href="#" ng-click="$ctrl.loadUtestCaptcha()">Reload</a>
          </span>
        </div>
      </div>

    </div>
  </form>
</div>
`;
