import { KEY_CODES } from 'src/app/constants';

class CommunityTrendingCtrl {
  constructor() {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      ctrl.showMore = false;
    };

    ctrl.toggleShowMore = () => {
      ctrl.showMore = !ctrl.showMore;
    };

    ctrl.toggleShowMoreByKeydown = (event) => {
      const keyDown = event.key !== undefined ? event.key : event.keyCode;
      if ((keyDown === 'Enter' || keyDown === KEY_CODES.RETURN) || (['Spacebar', ' '].indexOf(keyDown) >= 0 || keyDown === KEY_CODES.SPACE)) {
        event.preventDefault();
        ctrl.toggleShowMore();
      }
    };
  }
}
export default CommunityTrendingCtrl;
