export default `
<div class="feed-item" ng-class="$ctrl.getWrapperClasses()">
  <div class="feed-item-header clearfix">
    <div class="feed-item-info" ng-style="$ctrl.rightInfo && {'float' : 'right'}">
      <a ng-href="{{$ctrl.display.header_info_link}}" aria-label="{{$ctrl.item.type}} Author, {{$ctrl.item.user.name}}">
        <span ng-if="$ctrl.item.type == 'project'" class="icon-thumb icon-projects"></span>
        <span avatar-badges="$ctrl.item.user" ng-if="$ctrl.item.type != 'project'" class="profile-thumb"></span>
        <span class="feed-item-info-name">{{$ctrl.display.header_info_text | strLimit: 20}}<span ng-if="$ctrl.item.type != 'project' && $ctrl.display.country"> ({{$ctrl.display.country}})</span></span>
        <div class="feed-item-header-title" ng-if="$ctrl.item.type != 'project' && $ctrl.display.header_info_title">{{$ctrl.display.header_info_title | strLimit: 50}}</div>
        <small>
          <span class="active" ng-if="$ctrl.isContentPinned && $ctrl.showPin"><i class="icon icon-pin"></i></span>
          {{$ctrl.display.type|capitalize}}
          <span ng-if="$ctrl.display.date && $ctrl.display.type"> • </span>
          <span ng-if="$ctrl.display.date">{{$ctrl.display.date | relativeDate}}</span>
        </small>
      </a>
    </div>
    <div class="actions func-btns" ng-if="$ctrl.item.type!='user'">
      <span ng-if="$ctrl.singleView && $ctrl.item.type == 'status'">
        <social-sharing target="$ctrl.display.fullUrl" text="$ctrl.display.description" type="Status"></social-sharing>
      </span>
      <community-feed-item-hide ng-if="['status', 'comment', 'article', 'topic'].indexOf($ctrl.item.type) !== -1" item="$ctrl.item" ng-show="$ctrl.item.current_user_permissions.hide" on-hide="$ctrl.removeItem($ctrl.item)"></community-feed-item-hide>
      <community-feed-item-flag item="$ctrl.item" ng-if="['status', 'article', 'topic'].indexOf($ctrl.item.type) !== -1"  ng-show="$ctrl.item.current_user_permissions.flag"></community-feed-item-flag>
      <span ng-if="$ctrl.singleView && $ctrl.item.type == 'status'">
        <community-feed-item-subscribe item="$ctrl.item"></community-feed-item-subscribe>
      </span>

      <button class="community-feed-item-pin" ng-class="{active: $ctrl.isContentPinned}" ng-if="$ctrl.pinnable" ng-click="$ctrl.pinContent()">
        <i class="material-icons icon-pin"></i>
      </button>

      <hidden-menu icon-class="material-icons" icon-name="create" ng-if="$ctrl.item.type == 'status' && ($ctrl.item.current_user_permissions.update || $ctrl.item.current_user_permissions.destroy)">
        <li ng-if="$ctrl.item.current_user_permissions.update" class="hidden-mobile">
          <a class="clickable" ng-mouseup="toggleSlideUp('editStatus' + $ctrl.item.id)">Edit</a>
        </li>
        <li ng-if="$ctrl.item.current_user_permissions.update" class="visible-mobile">
          <a class="clickable" write-status-fs-trigger status="$ctrl.item">Edit</a>
        </li>
        <li ng-if="$ctrl.item.current_user_permissions.destroy">
          <a class="clickable" ng-mouseup="toggleSlideUp('deleteItem' + $ctrl.item.id)">Delete</a>
        </li>
      </hidden-menu>
    </div>
    <div class="actions" ng-if="$ctrl.item.type=='user' && $ctrl.item.current_user_permissions['follow']">
      <community-follow-btn user="$ctrl.item"></community-follow-btn>
    </div>
  </div>
  <div class="feed-item-sub-title" ng-if="$ctrl.display.sub_title">
    <div ng-if="$ctrl.item.type == 'topic'" class="discussion-cate">
      <a ui-sref="discussioncategory({categoryId : $ctrl.item.category.id})">
        <span discussion-category="{{$ctrl.item.category_type}}">{{$ctrl.item.category.parent.name|categoryType}}:</span> {{$ctrl.item.category.name }}
      </a>
    </div>
  </div>
  <div ng-if="$ctrl.display.image" class="feed-item-img" bg-img="{{$ctrl.display.image}}">
  </div>
  <div class="feed-item-title" ng-if="$ctrl.display.title">
    <a ng-click="$ctrl.tracking()" ng-href="{{$ctrl.display.url}}" ng-bind-html="$ctrl.display.title | cleanUp | ulinky"></a>
    <span class="icon-check green" ng-if="$ctrl.display.user_has_applied"></span>
  </div>
  <p ng-if="$ctrl.item.type != 'status' && $ctrl.display.description" class="feed-item-description" ng-bind-html="$ctrl.display.description | cleanUp | ulinky"></p>
  <p ng-if="$ctrl.item.type == 'status' && $ctrl.display.description" ng-click="$ctrl.goToStatus($event)" class="feed-item-description clickable" ng-bind-html="$ctrl.display.description | ulinky | mentions:$ctrl.item.mentions "></p>
  <status-attachment ng-if="$ctrl.item.type == 'status' && $ctrl.item.attached_url" status="$ctrl.item"></status-attachment>
  <div ng-if="$ctrl.showFlagReason" class="flag_reason">
    <p class="heading">Flag Reasons</p>
    <div ng-repeat="flagged_reason in $ctrl.item.flagged_reason" style="padding-bottom: 1rem;">
      <div class="reason">{{flagged_reason.reason}}</div>
      <small style="color: #7b8a97;">
        <span> Reported By: <a ui-sref="profile.about({profileId : flagged_reason.reporter_slug})">{{flagged_reason.name}}</a> • </span>
        <span>{{flagged_reason.created_at | relativeDate}}</span>
      </small>
    </div>
  </div>
  <div class="feed-item-footer clearfix" ng-if="$ctrl.item.type != 'user' && $ctrl.item.type != 'status'">
    <div class="actions">
      <ut-community-feed-item-like-count [likeable]="$ctrl.item" ng-if="!['project', 'course'].includes($ctrl.item.type)"></ut-community-feed-item-like-count>
      <ut-community-feed-item-like-btn [likeable]="$ctrl.item" ng-if="!['project', 'course'].includes($ctrl.item.type)"></ut-community-feed-item-like-btn>
      <ut-comment-btn [commentable]="$ctrl.item" ng-if="!['project', 'course', 'tool'].includes($ctrl.item.type)"></ut-comment-btn>
    </div>
    <div ng-if="$ctrl.showTags" class="tags">
      <ut-tag-btn [tag]="tag" ng-repeat="tag in $ctrl.item.tags"></ut-tag-btn>
    </div>
  </div>
  <comment-section-short ng-if="!$ctrl.singleView && $ctrl.item.type == 'status'" commentable="$ctrl.item"></comment-section-short>
  <section ng-if="$ctrl.item.type == 'status'" class="slide-up slide-up-move" id="editStatus{{$ctrl.item.id}}">
    <p>Edit Status</p>
    <div class="easy-write" ng-class="status">
      <div class="easy-write-content">
        <write-status success-callback="$ctrl.statusChanged" status="$ctrl.item" current-user="currentUser" popover-placement="top"></write-status>
      </div>
      <div class="easy-write-status">
        <p>Status Successfully Posted!</p>
      </div>
    </div>
  </section>
  <section class="slide-up slide-up-move" id="deleteItem{{$ctrl.item.id}}">
    <p>Are you sure you want to delete this {{$ctrl.item.type}}?</p>
    <p>
      <a ng-click="$ctrl.deleteItem()" class="btn small btn-yellow">Delete</a>
      <a ng-click="toggleSlideUp('deleteItem' + $ctrl.item.id)" class="btn small">Cancel</a>
    </p>
  </section>
</div>
`;
