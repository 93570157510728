export default `
<div id="mainContent">
  <community-nav></community-nav>
  <uib-alert type="{{instruction.type}}" close="closeAlert($index)" ng-repeat="instruction in instructions">
    <div class="container-fluid" ng-bind-html="instruction.message" ></div>
  </uib-alert>
  <div class="container-fluid" >
      <h1>New Topic</h1>
      <form ng-submit="save()" class="form-clean" >
        <div class="input-row-invisible">
          <label>Topic Category</label><span aria-hidden="true"> *Required</span>
          <ui-select role="combobox" aria-expanded="{{$select.open}}" aria-required="true" ng-model="discussion.category" aria-label=", {{$select.selected.fullName}} ">
            <ui-select-match placeholder="Select a category" >{{$select.selected.fullName}}</ui-select-match>
              <ui-select-choices repeat="item in categories | filter: $select.search">
                <div ng-bind-html="item.fullName | highlight: $select.search"></div>
              </ui-select-choices>
          </ui-select>
        </div>
        <div class="input-row-invisible">
          <label for="discussionTitle">Discussion Title</label><span aria-hidden="true"> *Required</span>
          <input ng-model="discussion.title" placeholder="Discussion Title" type="text" aria-required="true" id="discussionTitle">
        </div>
        <div class="input-row-invisible">
          <label>Topic Text</label><span aria-hidden="true"> *Required</span>
          <ut-editor [(value)]="discussion.content" text-required="true" text-area-label="Topic Text"></ut-editor>
        </div>
      </form>
  </div>
</div>
<div class="editor-footer">
  <div class="container-fluid editor-footer-content" >
    <button type="button" ng-disabled="saveInProgress" class="btn editor-footer-action-btn topic" ng-click="save()">Create</button>
  </div>
</div>
`;
