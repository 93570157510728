export default `
<!--article feed -->
<div class="feed article-two_column">
  <div class="feed-header clearfix">
    <a class="feed-item-info" ui-sref="profile.about({profileId: $ctrl.article.user.slug})" aria-label="Article Author, {{$ctrl.article.user.name}}">
        <div class="feed-header-info-icon">
          <div class="profile-thumb" bg-img="{{$ctrl.article.user.avatar_url}}"></div>
        </div>
        <div class="feed-header-info-name">
              {{$ctrl.article.user.name | strLimit: 20}}
              <span ng-if="$ctrl.article.user.country.name">({{$ctrl.article.user.country.name}})</span>
          <div class="feed-item-header-title" ng-if="$ctrl.article.user.user_title">{{$ctrl.article.user.user_title | strLimit: 40}}</div>
        </div>
    </a>
  </div>

  <div class="feed-content">
    <div class="feed-content-heading clearfix">
      <div><a ui-sref="singlearticle({articleId : $ctrl.article.slug})">{{$ctrl.article.title}}</a></div>
    </div>
    <div class="feed-content-footer">
      <div class="status-btns">
        <ut-community-feed-item-like-count [likeable]="$ctrl.article"></ut-community-feed-item-like-count>
        <ut-community-feed-item-like-btn [likeable]="$ctrl.article"></ut-community-feed-item-like-btn>
        <ut-comment-btn [commentable]="$ctrl.article"></ut-comment-btn>
      </div>
      <div class="timestamp">{{$ctrl.article.published_at | relativeDate}}</div>
    </div>
  </div>
</div>
`;
