export default `
<div id="mainContent">
  <community-nav ng-if="currentUser"></community-nav>
  <div class="section-home-cover">
    <div class="section-home-cover-bg">
      <div bg-img="{{coverBg}}" swap="{{headerData.coverBg.large}}" ></div>
    </div>
    <div class="block container-fluid">
      <a ng-if="canCreateTopic" ui-sref="newDiscussion" id="coverBtn" class="btn new-topic" ng-click="checkLightTester($event)">Create a New Topic</a>
      <h1 class="section-title">{{headerData.title}}</h1>
      <p class="section-desc" ng-bind-html="headerData.description"></p>
      <div class="section-home-cover-random discussions" ng-repeat="randomDiscussion in randomDiscussions | limitTo:2">
        <div class="func-btns">
          <social-sharing is-popover="true" target="randomDiscussion.url" text="randomDiscussion.title | cleanUp | ulinky" type="Topic"></social-sharing>
        </div>
        <div class="discussion-cate"><span class="{{randomDiscussion.type}}">{{randomDiscussion.type}}:</span> {{randomDiscussion.category.name}}</div>
        <a class="discussion-title-link"analytics-on="click" analytics-event="featured_topic" analytics-category="click" analytics-label="{{randomDiscussion.id}}" ui-sref="singlediscussionpage({page: 1, discussionId : randomDiscussion.id})">{{randomDiscussion.title}}</a>
        <p>{{randomDiscussion.content | limitTo: 300}}</p>
        <div class="tags">
          <ut-tag-btn [tag]="tag" ng-repeat="tag in randomDiscussion.tags"></ut-tag-btn>
        </div>
        <div class="status-btns">
          <a ui-sref="singlediscussionpage({page: 1, discussionId: randomDiscussion.id})" class="feed-comment icon-chat active" ng-click="checkLightTester($event)"
             data-value="{{randomDiscussion.post_count}}" aria-label="{{randomDiscussion.post_count}} comments, {{randomDiscussion.title}}"></a>
        </div>
      </div>
    </div>
  </div>
  <div class="block container-fluid" id="comment">
    <ui-select role="combobox" aria-expanded="{{$select.open}}" aria-label="{{$select.selected.fullName}}"
               class="select2-theme-form" ng-model="filterCategory.id">
      <ui-select-match aria-label="{{$select.selected.fullName}}" tabindex="0" allow-clear placeholder="All Categories">{{$select.selected.fullName}}</ui-select-match>
      <ui-select-choices minimum-input-length="-1" repeat="category.id as category in categories | filter: $select.search">
        <div ng-bind-html="category.fullName | highlight: $select.search"></div>
      </ui-select-choices>
    </ui-select>
    <uib-tabset>
      <uib-tab heading="Recent Replies" ui-sref="discussion({tab: 'recent-replies', topic_page: 1, category_id: filterCategory.id})" active="tab['recent-replies']">
        <feed-item class="compressed" item='discussion' ng-if="!isLoading" ng-repeat='discussion in discussions'></feed-item>
        <loading-dots ng-if="isLoading" ></loading-dots>
        <feed-paginator pagination-data="paginationData" load-page="loadPage"></feed-paginator>
      </uib-tab>
      <uib-tab heading="Newly Created" ui-sref="discussion({tab: 'newly-created', topic_page: 1, category_id: filterCategory.id})" active="tab['newly-created']">
        <feed-item  class="compressed" item='discussion' ng-if="!isLoading" ng-repeat='discussion in discussions'></feed-item>
        <loading-dots ng-if="isLoading" ></loading-dots>
        <feed-paginator pagination-data="paginationData" load-page="loadPage"></feed-paginator>
      </uib-tab>
      <uib-tab heading="Most Replies" ui-sref="discussion({tab: 'most-replies', topic_page: 1, category_id: filterCategory.id})" active="tab['most-replies']" >
        <feed-item class="compressed" item='discussion' ng-if="!isLoading" ng-repeat='discussion in discussions'></feed-item>
        <loading-dots ng-if="isLoading" ></loading-dots>
        <feed-paginator pagination-data="paginationData" load-page="loadPage"></feed-paginator>
      </uib-tab>
    </uib-tabset>
  </div>
</div>
`;
