/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import angular from 'angular';
import { TESTCYCLE_STATUS } from '../../../constants';

class DashboardActivityProjectListItemCtrl {
  constructor() {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      this.testCycleStatusIds = TESTCYCLE_STATUS;

      if (this.itemType === 'project') {
        this.testCycleStartDate = this.project.activationDate || this.project.startDate;
      }
    };

    ctrl.$onChanges = function (changes) {
      if (changes.testerAppUrl) {
        this.testerAppUrl = angular.copy(changes.testerAppUrl.currentValue);
      }
    };
  }
}

export default DashboardActivityProjectListItemCtrl;
