export default `
<div class="slug-input" ng-class="{'slug-input-edit': $ctrl.editMode, 'has-error': $ctrl.hasError}">
  <div ng-show="$ctrl.changeAlert" class="alert alert-warning">
    WARNING: Changing the slug will cause any previous links to this article to break and is not recommended. To revert to the original slug for this article, <a href="#" ng-click="$ctrl.setOriginal()" >click here</a>.
  </div>
  <div class="input-group" ng-show="$ctrl.toSlug.length || $ctrl.theSlug.length">
    <div class="input-group-addon clickable" ng-click="$ctrl.editMode = true" ng-bind="$ctrl.urlPre" ></div>
    <input type="text" ng-model="$ctrl.theSlug" ng-focus="$ctrl.editMode = true" ng-change="$ctrl.theSlugChange()" class="form-control" >
    <div role="button" tabindex="0" tooltip="Back to automatic slug" aria-label="reset slug to automatic" tooltip-placement="left" ng-show="$ctrl.editMode" class="input-group-addon" ng-keydown="$ctrl.setAutomaticByKeyDown($event)" ng-click="$ctrl.setAutomatic()"><i class="fa fa-close"></i></div>
  </div>
</div>
`;
