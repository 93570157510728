export default `
<div class="dashboard-card-list-item dashboard-card-list-item--activity">
  <div class="dashboard-activity-project-status">
    <div ng-if="$ctrl.itemType == 'invitation'" class="dashboard-activity-project-status__icon dashboard-activity-project-status__icon--invitation">
      <i class="material-icons">email</i>
    </div>

    <div ng-if="$ctrl.itemType == 'project'" class="dashboard-activity-project-status__icon" ng-class="$ctrl.project.testCycleStatusId == $ctrl.testCycleStatusIds.ACTIVE ? 'dashboard-activity-project-status__icon--active' : 'dashboard-activity-project-status__icon--pending'">
      <i class="material-icons">assignment</i>
    </div>
    <div ng-if="$ctrl.itemType == 'project'" class="dashboard-activity-project-emphasized-text dashboard-activity-project-status__text">{{$ctrl.project.testCycleStatusDescriptor.name}}</div>
  </div>

  <div ng-if="$ctrl.itemType == 'project'" class="dashboard-activity-project-details">
    <div class="dashboard-card-activity-my-projects-title-block">
      <a href="{{$ctrl.testerAppUrl}}/testcycles/{{$ctrl.project.id}}/" class="dashboard-activity-project-link">
        {{$ctrl.project.name}}
      </a>
    </div>
    <div class="dashboard-card-activity-my-projects-stats">
      <div class="dashboard-card-activity-my-projects-stat">
        <i class="material-icons dashboard-activity-project-icon">people</i>
        {{$ctrl.project.numberOfTesters}} {{$ctrl.project.numberOfTesters == 1 ? 'tester' : 'testers'}}
      </div>
      <div class="dashboard-card-activity-my-projects-stat">
        <i class="material-icons dashboard-activity-project-icon">bug_report</i>
        {{$ctrl.project.totalBugs}} {{$ctrl.project.totalBugs == 1 ? 'bug' : 'bugs'}}
      </div>
    </div>
  </div>

  <div ng-if="$ctrl.project.action == 'invitation'" class="dashboard-activity-project-details">
    <div ng-if="$ctrl.project.recruited" class="dashboard-card-activity-my-projects-recruited">
      <i class="material-icons dashboard-activity-project-icon">label_outline</i>
      You've Been Recruited!
    </div>
    <div class="dashboard-card-activity-my-projects-title-block">
      <a href="{{$ctrl.testerAppUrl}}/testcycles/{{$ctrl.project.testcycle.id}}/" class="dashboard-activity-project-link">
        {{$ctrl.project.testcycle.name}}
      </a>
    </div>
    <div class="dashboard-card-activity-my-projects-stats">
      <div class="dashboard-card-activity-my-projects-stat">
        <i class="material-icons dashboard-activity-project-icon">people</i>
        {{$ctrl.project.testcycle.participants}} {{$ctrl.project.testcycle.participants == 1 ? 'tester' : 'testers'}}
      </div>
      <div ng-if="!$ctrl.project.mfaRequired" class="dashboard-card-activity-my-projects-stat">
        <i class="material-icons dashboard-activity-project-icon">bug_report</i>
        {{$ctrl.project.testcycle.totalBugs}} {{$ctrl.project.testcycle.totalBugs == 1 ? 'bug' : 'bugs'}}
      </div>
    </div>
  </div>

  <div ng-if="$ctrl.project.action == 'requirement_survey'" class="dashboard-activity-project-details">
    <div class="dashboard-card-activity-my-projects-title-block">
      <div class="dashboard-activity-project-emphasized-text">Requirement Survey Requested</div>
      <a href="{{$ctrl.testerAppUrl}}/requirement-survey/{{$ctrl.project.requirementSurveyId}}" class="dashboard-activity-project-link">
        {{$ctrl.project.name}}
      </a>
    </div>
  </div>

  <div ng-if="$ctrl.itemType == 'project'" class="dashboard-activity-project-timing">
    <div class="dashboard-activity-project-timing__dates">
      <div class="dashboard-activity-project-timing__date">
        <span class="dashboard-activity-project-emphasized-text">Start:</span> {{$ctrl.testCycleStartDate | date: 'shortDate'}} - {{$ctrl.testCycleStartDate | date: 'shortTime'}}
      </div>
      <div class="dashboard-activity-project-timing__date" ng-if="$ctrl.project.deactivationDate">
        <span class="dashboard-activity-project-emphasized-text">End:</span> {{$ctrl.project.deactivationDate | date: 'shortDate'}} - {{$ctrl.project.deactivationDate | date: 'shortTime'}}
      </div>
    </div>
    <div class="dashboard-activity-project-timing__countdown">
      <i class="material-icons dashboard-activity-project-icon">timer</i>
      <span class="dashboard-activity-project-emphasized-text" ng-if="$ctrl.project.deactivationDate">Locks: {{$ctrl.project.deactivationDate | timeLeft : undefined : $ctrl.project.testCycleStatusId === $ctrl.testCycleStatusIds.ACTIVE}}</span>
      <span class="dashboard-activity-project-emphasized-text" ng-if="!$ctrl.project.deactivationDate">Locks: TBD</span>
    </div>
  </div>

  <div ng-if="$ctrl.project.action == 'invitation'" class="dashboard-activity-project-timing">
    <div class="dashboard-activity-project-timing__dates">
      <div class="dashboard-activity-project-timing__date">
        <span class="dashboard-activity-project-emphasized-text">Start:</span> {{$ctrl.project.testcycle.startDate | date: 'shortDate'}} - {{$ctrl.project.testcycle.startDate | date: 'shortTime'}}
      </div>
      <div class="dashboard-activity-project-timing__date" ng-if="$ctrl.project.testcycle.endDate">
        <span class="dashboard-activity-project-emphasized-text">End:</span> {{$ctrl.project.testcycle.endDate | date: 'shortDate'}} - {{$ctrl.project.testcycle.endDate | date: 'shortTime'}}
      </div>
    </div>
    <div class="dashboard-activity-project-timing__countdown">
      <i class="material-icons dashboard-activity-project-icon">timer</i>
      <span class="dashboard-activity-project-emphasized-text" ng-if="$ctrl.project.testcycle.endDate">Locks: {{$ctrl.project.testcycle.endDate | timeLeft : undefined : $ctrl.project.testcycle.testCycleStatusId === $ctrl.testCycleStatusStrings.active}}</span>
      <span class="dashboard-activity-project-emphasized-text" ng-if="!$ctrl.project.testcycle.endDate">Locks: TBD</span>
    </div>
  </div>

  <div ng-if="$ctrl.project.action == 'requirement_survey'" class="dashboard-activity-project-timing">
    <div class="dashboard-activity-project-timing__dates">
      <div class="dashboard-activity-project-timing__date">
        <span class="dashboard-activity-project-emphasized-text" ng-if="$ctrl.project.endDate">End:</span> {{$ctrl.project.endDate | date: 'shortDate'}} - {{$ctrl.project.endDate | date: 'shortTime'}}
      </div>
    </div>
  </div>
</div>
`;
