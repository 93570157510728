import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { CommentBtnComponent } from './comment-btn/comment-btn.component';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule,
  ],
  declarations: [
    CommentBtnComponent,
  ],
  exports: [
    CommentBtnComponent,
  ],
})
class SharedModule {}

export { SharedModule };
